import { createApi } from '@reduxjs/toolkit/query/react';
import { DeleteUserBulkMutationParams, UserDsQueryParams, UserDsResponse } from './types/user';
import { baseQuery } from './general';

interface ResponsTypeEditUser {
    id: number;
    data: any;
}

type ArgsTypesEditUser = {
    id?: number;
    data?: any;
};

export const usersApi = createApi({
    reducerPath: 'usersApi',
    baseQuery,
    // TODO: add types for endpoints
    endpoints: (builder) => ({
        getMe: builder.query<any, void>({
            query: () => ({
                url: `/auth/me`,
                method: 'GET'
            })
        }),
        getUser: builder.query({
            query: (id) => ({
                url: `/admin/users/${id}`,
                method: 'GET'
            })
        }),
        getSupliersList: builder.query({
            query: (params) => ({
                url: `/admin/suppliers`,
                method: 'GET',
                params
            })
        }),

        getUserDs: builder.query<UserDsResponse, UserDsQueryParams>({
            query(params) {
                return {
                    url: '/admin/users',
                    method: 'GET',
                    params
                };
            },
            keepUnusedDataFor: 0
        }),
        createUser: builder.mutation({
            query: (data) => {
                return {
                    url: '/admin/users',
                    method: 'POST',
                    formData: true,
                    body: data
                };
            }
        }),
        editUser: builder.mutation<ResponsTypeEditUser, ArgsTypesEditUser>({
            query: (data) => ({
                url: `/admin/users/${data.id}`,
                method: 'POST',
                formData: true,
                body: data.data
            })
        }),
        deleteUser: builder.mutation({
            query: (id) => ({
                url: `/admin/users/${id}`,
                method: 'DELETE'
            })
        }),
        deleteSupplier: builder.mutation({
            query: (id) => ({
                url: `/admin/suppliers/${id}`,
                method: 'DELETE'
            })
        }),
        deleteUserBulk: builder.mutation<any, DeleteUserBulkMutationParams>({
            query: (params) => ({
                url: '/admin/users/bulk-delete',
                method: 'Delete',
                params
            })
        }),
        deleteSupplierBulk: builder.mutation<any, DeleteUserBulkMutationParams>({
            query: (params) => ({
                url: '/admin/suppliers/bulk-delete',
                method: 'Delete',
                params
            })
        }),
        resendInvite: builder.mutation<ResponsTypeEditUser, ArgsTypesEditUser>({
            query: (id) => ({
                url: `/admin/users/${id}/resend-invite`,
                method: 'POST'
            })
        }),
        getInvatationData: builder.query({
            query: (token) => ({
                url: `/auth/invitation-data?token=${token}`,
                method: 'GET'
            })
        }),
        signUp: builder.mutation({
            query: (data) => ({
                url: `/auth/sign-up?token=${data.token}`,
                method: 'POST',
                formData: true,
                body: data.data
            })
        }),
        createSupplier: builder.mutation({
            query: (data) => ({
                url: `/supplier-companies/registration${data?.step === 1 ? '' : '?step=submit'}`,
                method: 'POST',
                body: data.data
            })
        }),
        createSupplierForm: builder.mutation({
            query: (data) => ({
                url: `/admin/suppliers${data?.step === 1 ? '' : '?step=submit'}`,
                method: 'POST',
                body: data.data
            })
        })
    })
});

export const {
    useLazyGetUserQuery,
    useGetUserQuery,
    useCreateUserMutation,
    useEditUserMutation,
    useDeleteUserMutation,
    useDeleteUserBulkMutation,
    useGetUserDsQuery,
    useResendInviteMutation,
    useLazyGetInvatationDataQuery,
    useSignUpMutation,
    useGetMeQuery,
    useLazyGetMeQuery,
    useLazyGetUserDsQuery,
    useCreateSupplierMutation,
    useGetSupliersListQuery,
    useDeleteSupplierBulkMutation,
    useDeleteSupplierMutation,
    useCreateSupplierFormMutation
} = usersApi;
